/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { isWebMercator as n, isGeographic as r } from "./spatialReferenceUtils.js";
import { xyToLngLat as t } from "./webMercatorUtils.js";
function e(n, r) {
  const t = n.x - r.x,
    e = n.y - r.y,
    u = null != n.z && null != r.z ? n.z - r.z : 0;
  return Math.sqrt(t * t + e * e + u * u);
}
function u(n) {
  return i(n, o)?.[0] ?? null;
}
function l(n) {
  return i(n, o)?.[1] ?? null;
}
function i({
  x: e,
  y: u,
  spatialReference: l
}, i = [0, 0]) {
  if (l) {
    if (n(l)) return t(e, u, i);
    if (r(l)) return i[0] = e, i[1] = u, i;
  }
  return null;
}
const o = [0, 0];
export { e as distance, l as getLatitude, u as getLongitude, i as getLongitudeLatitude };