/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as e } from "../chunks/tslib.es6.js";
import { JSONSupport as r } from "../core/JSONSupport.js";
import { property as t } from "../core/accessorSupport/decorators/property.js";
import "../core/has.js";
import "../core/Logger.js";
import "../core/RandomLCG.js";
import { reader as o } from "../core/accessorSupport/decorators/reader.js";
import { subclass as s } from "../core/accessorSupport/decorators/subclass.js";
import a from "./SpatialReference.js";
let c = class extends r {
  constructor(...e) {
    super(...e), this.type = null, this.hasM = !1, this.hasZ = !1, this.spatialReference = a.WGS84;
  }
  get cache() {
    return this.commitProperty("spatialReference"), {};
  }
  get extent() {
    return null;
  }
  readSpatialReference(e, r) {
    if (e instanceof a) return e;
    if (null != e) {
      const t = new a();
      return t.read(e, r), t;
    }
    return e;
  }
  clone() {
    return console.warn(".clone() is not implemented for " + this.declaredClass), null;
  }
  clearCache() {
    this.notifyChange("cache");
  }
  getCacheValue(e) {
    return this.cache[e];
  }
  setCacheValue(e, r) {
    this.cache[e] = r;
  }
};
e([t()], c.prototype, "type", void 0), e([t({
  readOnly: !0
})], c.prototype, "cache", null), e([t({
  readOnly: !0
})], c.prototype, "extent", null), e([t({
  type: Boolean,
  json: {
    write: {
      overridePolicy: e => ({
        enabled: e
      })
    }
  }
})], c.prototype, "hasM", void 0), e([t({
  type: Boolean,
  json: {
    write: {
      overridePolicy: e => ({
        enabled: e
      })
    }
  }
})], c.prototype, "hasZ", void 0), e([t({
  type: a,
  json: {
    write: !0
  },
  value: a.WGS84
})], c.prototype, "spatialReference", void 0), e([o("spatialReference")], c.prototype, "readSpatialReference", null), c = e([s("esri.geometry.Geometry")], c);
const n = c;
export { n as default };