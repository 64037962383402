/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as t } from "../chunks/tslib.es6.js";
import e from "../core/Logger.js";
import { property as r } from "../core/accessorSupport/decorators/property.js";
import { cast as s } from "../core/accessorSupport/decorators/cast.js";
import "../core/RandomLCG.js";
import "../core/has.js";
import { reader as i } from "../core/accessorSupport/decorators/reader.js";
import { subclass as o } from "../core/accessorSupport/decorators/subclass.js";
import { writer as l } from "../core/accessorSupport/decorators/writer.js";
import { ensureNumber as a } from "../core/accessorSupport/ensureType.js";
import n from "./Geometry.js";
import u from "./SpatialReference.js";
import { getLatitude as p, getLongitude as c, distance as h } from "./support/pointUtils.js";
import { getInfo as d } from "./support/spatialReferenceUtils.js";
import { lngLatToXY as y, xyToLngLat as m } from "./support/webMercatorUtils.js";
var f;
const g = [0, 0];
function w(t) {
  return t && ("esri.geometry.SpatialReference" === t.declaredClass || null != t.wkid);
}
let x = f = class extends n {
  static copy(t, e) {
    e._set("x", t._get("x")), e._set("y", t._get("y")), e._set("z", t._get("z")), e._set("m", t._get("m"));
    const r = t._get("spatialReference");
    e._set("spatialReference", Object.isFrozen(r) ? r : r.clone());
  }
  constructor(...t) {
    super(...t), this.x = 0, this.y = 0, this.z = void 0, this.m = void 0, this.type = "point";
  }
  normalizeCtorArgs(t, r, s, i, o) {
    let l;
    if (Array.isArray(t)) l = t, o = r, t = l[0], r = l[1], s = l[2], i = l[3];else if (t && "object" == typeof t) {
      if (l = t, t = null != l.x ? l.x : l.longitude, r = null != l.y ? l.y : l.latitude, s = l.z, i = l.m, (o = l.spatialReference) && "esri.geometry.SpatialReference" !== o.declaredClass && (o = new u(o)), null != l.longitude || null != l.latitude) if (null == l.longitude) e.getLogger(this).warn(".longitude=", "Latitude was defined without longitude");else if (null == l.latitude) e.getLogger(this).warn(".latitude=", "Longitude was defined without latitude");else if (!l.declaredClass && o?.isWebMercator) {
        const e = y(l.longitude, l.latitude, g);
        t = e[0], r = e[1];
      }
    } else w(s) ? (o = s, s = null) : w(i) && (o = i, i = null);
    const a = {
      x: t,
      y: r
    };
    return null == a.x && null != a.y ? e.getLogger(this).warn(".y=", "Y coordinate was defined without an X coordinate") : null == a.y && null != a.x && e.getLogger(this).warn(".x=", "X coordinate was defined without a Y coordinate"), null != o && (a.spatialReference = o), null != s && (a.z = s), null != i && (a.m = i), a;
  }
  get cache() {
    return this.commitProperty("x"), this.commitProperty("y"), this.commitProperty("z"), this.commitProperty("m"), this.commitProperty("spatialReference"), {};
  }
  get hasM() {
    return void 0 !== this.m;
  }
  set hasM(t) {
    t !== (void 0 !== this._get("m")) && (this._set("m", t ? 0 : void 0), this._set("hasM", t));
  }
  get hasZ() {
    return void 0 !== this.z;
  }
  set hasZ(t) {
    t !== (void 0 !== this._get("z")) && (this._set("z", t ? 0 : void 0), this._set("hasZ", t));
  }
  get latitude() {
    return p(this);
  }
  set latitude(t) {
    const {
      spatialReference: e,
      x: r
    } = this;
    null != t && e && (e.isWebMercator ? this._set("y", y(r, t, g)[1]) : e.isGeographic && this._set("y", t), this._set("latitude", t));
  }
  get longitude() {
    return c(this);
  }
  set longitude(t) {
    const {
      y: e,
      spatialReference: r
    } = this;
    null != t && r && (r.isWebMercator ? this._set("x", y(t, e, g)[0]) : r.isGeographic && this._set("x", t), this._set("longitude", t));
  }
  writeX(t, e, r) {
    e[r] = isNaN(t) ? "NaN" : t;
  }
  readX(t) {
    return "string" == typeof t ? NaN : t;
  }
  clone() {
    const t = new f();
    return t.x = this.x, t.y = this.y, t.z = this.z, t.m = this.m, t.spatialReference = this.spatialReference, t;
  }
  copy(t) {
    return f.copy(t, this), this;
  }
  equals(t) {
    if (null == t) return !1;
    const {
        x: e,
        y: r,
        z: s,
        m: i,
        spatialReference: o
      } = this,
      {
        z: l,
        m: a
      } = t;
    let {
      x: n,
      y: u,
      spatialReference: p
    } = t;
    if (!o.equals(p)) if (o.isWebMercator && p.isWGS84) [n, u] = y(n, u), p = o;else {
      if (!o.isWGS84 || !p.isWebMercator) return !1;
      [n, u] = m(n, u), p = o;
    }
    return e === n && r === u && s === l && i === a && o.wkid === p.wkid;
  }
  offset(t, e, r) {
    return this.x += t, this.y += e, null != r && (this.z = (this.z ?? 0) + r), this;
  }
  normalize() {
    if (!this.spatialReference) return this;
    const t = d(this.spatialReference);
    if (!t) return this;
    let e = this.x;
    const [r, s] = t.valid,
      i = 2 * s;
    let o;
    return e > s ? (o = Math.ceil(Math.abs(e - s) / i), e -= o * i) : e < r && (o = Math.ceil(Math.abs(e - r) / i), e += o * i), this._set("x", e), this;
  }
  distance(t) {
    return h(this, t);
  }
  toArray() {
    const t = this.hasZ,
      e = this.hasM;
    return t && e ? [this.x, this.y, this.z, this.m] : t ? [this.x, this.y, this.z] : e ? [this.x, this.y, this.m] : [this.x, this.y];
  }
  toJSON(t) {
    return this.write({}, t);
  }
};
t([r({
  readOnly: !0
})], x.prototype, "cache", null), t([r({
  type: Boolean,
  json: {
    read: !1,
    write: {
      enabled: !1,
      overridePolicy: null
    }
  }
})], x.prototype, "hasM", null), t([r({
  type: Boolean,
  json: {
    read: !1,
    write: {
      enabled: !1,
      overridePolicy: null
    }
  }
})], x.prototype, "hasZ", null), t([r({
  type: Number
})], x.prototype, "latitude", null), t([r({
  type: Number
})], x.prototype, "longitude", null), t([r({
  type: Number,
  json: {
    type: [Number, String],
    write: {
      isRequired: !0,
      allowNull: !0
    }
  }
}), s(t => isNaN(t) ? t : a(t))], x.prototype, "x", void 0), t([l("x")], x.prototype, "writeX", null), t([i("x")], x.prototype, "readX", null), t([r({
  type: Number,
  json: {
    write: !0
  }
})], x.prototype, "y", void 0), t([r({
  type: Number,
  json: {
    write: {
      overridePolicy() {
        return {
          enabled: this.hasZ
        };
      }
    }
  }
})], x.prototype, "z", void 0), t([r({
  type: Number,
  json: {
    write: {
      overridePolicy() {
        return {
          enabled: this.hasM
        };
      }
    }
  }
})], x.prototype, "m", void 0), x = f = t([o("esri.geometry.Point")], x), x.prototype.toJSON.isDefaultToJSON = !0;
const _ = x;
export { _ as default };