/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { clone as n } from "../../core/lang.js";
import t from "../SpatialReference.js";
import { earth as e } from "./Ellipsoid.js";
import { equals as i, isWebMercator as r, isWGS84 as s } from "./spatialReferenceUtils.js";
const a = 57.29577951308232,
  o = .017453292519943;
function u(n) {
  return n * a;
}
function l(n) {
  return n * o;
}
function p(n) {
  return n / e.radius;
}
function f(n) {
  return Math.PI / 2 - 2 * Math.atan(Math.exp(-n / e.radius));
}
function c(n) {
  return null != n.wkid || null != n.wkt;
}
const h = [0, 0];
function m(n, t, e, i, r) {
  const s = n,
    a = r;
  if (a.spatialReference = e, "x" in s && "x" in a) [a.x, a.y] = t(s.x, s.y, h, i);else if ("xmin" in s && "xmin" in a) [a.xmin, a.ymin] = t(s.xmin, s.ymin, h, i), [a.xmax, a.ymax] = t(s.xmax, s.ymax, h, i);else if ("paths" in s && "paths" in a || "rings" in s && "rings" in a) {
    const n = "paths" in s ? s.paths : s.rings,
      e = [];
    let r;
    for (let s = 0; s < n.length; s++) {
      const a = n[s];
      r = [], e.push(r);
      for (let n = 0; n < a.length; n++) r.push(t(a[n][0], a[n][1], [0, 0], i)), a[n].length > 2 && r[n].push(a[n][2]), a[n].length > 3 && r[n].push(a[n][3]);
    }
    "paths" in a ? a.paths = e : a.rings = e;
  } else if ("points" in s && "points" in a) {
    const n = s.points,
      e = [];
    for (let r = 0; r < n.length; r++) e[r] = t(n[r][0], n[r][1], [0, 0], i), n[r].length > 2 && e[r].push(n[r][2]), n[r].length > 3 && e[r].push(n[r][3]);
    a.points = e;
  }
  return r;
}
function x(n, t) {
  const e = n && (c(n) ? n : n.spatialReference),
    a = t && (c(t) ? t : t.spatialReference);
  return !(n && "type" in n && "mesh" === n.type || t && "type" in t && "mesh" === t.type || !e || !a) && (!!i(a, e) || r(a) && s(e) || r(e) && s(a));
}
function g(e, a) {
  if (null == e) return null;
  const o = e.spatialReference,
    u = a && (c(a) ? a : a.spatialReference);
  return x(o, u) ? i(o, u) ? n(e) : r(u) ? m(e, M, t.WebMercator, !1, n(e)) : s(u) ? m(e, y, t.WGS84, !1, n(e)) : null : null;
}
function M(n, t, i = [0, 0]) {
  t > 89.99999 ? t = 89.99999 : t < -89.99999 && (t = -89.99999);
  const r = l(t);
  return i[0] = l(n) * e.radius, i[1] = e.halfSemiMajorAxis * Math.log((1 + Math.sin(r)) / (1 - Math.sin(r))), i;
}
function y(n, t, i = [0, 0], r = !1) {
  const s = u(n / e.radius);
  return i[0] = r ? s : s - 360 * Math.floor((s + 180) / 360), i[1] = u(Math.PI / 2 - 2 * Math.atan(Math.exp(-t / e.radius))), i;
}
function d(e, i = !1, r = n(e)) {
  return m(e, M, t.WebMercator, i, r);
}
function R(e, i = !1, r = n(e)) {
  return m(e, y, t.WGS84, i, r);
}
export { x as canProject, d as geographicToWebMercator, M as lngLatToXY, g as project, R as webMercatorToGeographic, p as x2lon, y as xyToLngLat, f as y2lat };