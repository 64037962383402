/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
const n = [0, 0];
function t(n, t) {
  return null != t && e(n, t.x, t.y, t.z);
}
function r(n, t) {
  if (!t.points || t.points.length) return !1;
  for (const r of t.points) if (!i(n, r)) return !1;
  return !0;
}
function u(n, t) {
  const {
    xmin: r,
    ymin: u,
    zmin: i,
    xmax: o,
    ymax: f,
    zmax: c
  } = t;
  return n.hasZ && t.hasZ ? e(n, r, u, i) && e(n, r, f, i) && e(n, o, f, i) && e(n, o, u, i) && e(n, r, u, c) && e(n, r, f, c) && e(n, o, f, c) && e(n, o, u, c) : e(n, r, u) && e(n, r, f) && e(n, o, f) && e(n, o, u);
}
function i(n, t) {
  return e(n, t[0], t[1]);
}
function o(n, t) {
  return e(n, t[0], t[1], t[2]);
}
function e(n, t, r, u) {
  return t >= n.xmin && t <= n.xmax && r >= n.ymin && r <= n.ymax && (null == u || !n.hasZ || u >= n.zmin && u <= n.zmax);
}
function f(t, r) {
  return n[1] = r.y, n[0] = r.x, c(t, n);
}
function c(n, t) {
  return s(n.rings, t);
}
function s(n, t) {
  if (!n) return !1;
  if (x(n)) return m(!1, n, t);
  let r = !1;
  for (let u = 0, i = n.length; u < i; u++) r = m(r, n[u], t);
  return r;
}
function x(n) {
  return !Array.isArray(n[0][0]);
}
function m(n, t, r) {
  const [u, i] = r;
  let o = n,
    e = 0;
  for (let f = 0, c = t.length; f < c; f++) {
    e++, e === c && (e = 0);
    const [n, r] = t[f],
      [s, x] = t[e];
    (r < i && x >= i || x < i && r >= i) && n + (i - r) / (x - r) * (s - n) < u && (o = !o);
  }
  return o;
}
export { i as extentContainsCoords2D, o as extentContainsCoords3D, u as extentContainsExtent, r as extentContainsMultipoint, t as extentContainsPoint, e as extentContainsXYZ, c as polygonContainsCoords, f as polygonContainsPoint, s as ringsContainsCoords };